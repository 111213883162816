const React = require('react');
const PropTypes = require('prop-types');

const sendReport = require('./utils/sendReport');

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const { extraInfo, baseURL, csrfToken } = this.props;
    const errorPage = typeof window === 'undefined' ? '' : window.location.href;
    const { message, stack } = error || {};

    if (message) {
      const data = {
        errorInfo,
        errorStack: stack,
        errorMessage: message,
        errorPage,
        date: new Date().toISOString(),
        from: 'catch-error-component',
        ...(extraInfo || {}),
      };

      sendReport(data, { baseURL, csrfToken });
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  extraInfo: PropTypes.shape({}),
  children: PropTypes.node,
  baseURL: PropTypes.string,
  csrfToken: PropTypes.string,
};

module.exports = ErrorBoundary;
