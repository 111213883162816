/* eslint-disable @meli-lint/react/i18n-jsx-no-literals */
const React = require('react');
const { bool } = require('prop-types');

const { useState } = React;

const { Modal } = require('@andes/modal');
const { Button } = require('@andes/button');
const { Typography } = require('@andes/typography');
const { Image } = require('nordic/image');

const { REBRAND_RESOURCE_SRC } = require('../utils/constants');

const ModalRebrand = ({ isMobile }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div className="modal-rebrand">
      <Modal
        className="modal-rebrand"
        open={isVisible}
        title=" "
        type={isMobile ? 'card' : 'small'}
        actions={{
          primary: (
            <Button
              fullWidth={isMobile ? 'fullWidth' : ''}
              type="button"
              onClick={handleClose}
            >
              Ok, entendi
            </Button>
          ),
        }}
        media={
          isMobile
            ? {
                asset: (
                  <Image
                    src={REBRAND_RESOURCE_SRC}
                    alt="Gif ilustrativo"
                    className="modal-rebrand__image"
                  />
                ),
              }
            : {}
        }
        onClose={handleClose}
      >
        {!isMobile && (
          <Image
            src={REBRAND_RESOURCE_SRC}
            alt="Gif ilustrativo"
            className="modal-rebrand__image"
          />
        )}
        <div className="modal-rebrand__text">
          <Typography className="modal-rebrand__title" type="title" size="l">
            Evolucionamos y nuestra marca también
          </Typography>
          <Typography
            className="modal-rebrand__description"
            type="body"
            size="l"
          >
            Reinventamos nuestra paleta de colores para estar más cerca de
            nuestra esencia
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

ModalRebrand.propTypes = {
  isMobile: bool,
};

module.exports = ModalRebrand;
