/* eslint-disable no-console */
const React = require('react');
const { useEffect } = require('react');
const PropTypes = require('prop-types');

const ErrorBoundary = require('./ErrorBoundary');
const errorConsole = require('./utils/errorConsole');
const errorEventConfig = require('./utils/errorEvent');

const ErrorCatchProvider = (props) => {
  const {
    children,
    isMobile,
    isWebView,
    isPreview,
    baseApiPath: { catchLogger: apiPathCatchLogger },
    csrfToken,
    landingConfig: {
      groupId,
      variantId,
      landingVersionId: versionId,
      productName,
      space,
    },
    device,
    scope,
    coreValues: { userId, coupon, channel, subChannel, siteId, userAgent },
  } = props;

  const extraInfo = {
    landingInfo: {
      scope,
      groupId,
      variantId,
      versionId,
      productName,
      space,
      isMobile,
      isWebView,
      isPreview,
    },
    userInfo: {
      device,
      userId,
      coupon,
      channel,
      subChannel,
      siteId,
      userAgent,
    },
  };

  useEffect(() => {
    let originalConsoleError;
    let originalOnError;

    if (!console.overwrite) {
      console.overwrite = true;

      originalConsoleError = console.error;
      console.error = errorConsole(apiPathCatchLogger, csrfToken);

      if (typeof window !== 'undefined') {
        originalOnError = window?.onerror;
        window.onerror = errorEventConfig(apiPathCatchLogger, csrfToken);
      }
    }

    return () => {
      console.error = originalConsoleError;

      if (typeof window !== 'undefined') {
        window.onerror = originalOnError;
      }
    };
  }, [apiPathCatchLogger, csrfToken]);

  return (
    <ErrorBoundary
      extraInfo={extraInfo}
      baseURL={apiPathCatchLogger}
      csrfToken={csrfToken}
    >
      {children}
    </ErrorBoundary>
  );
};

ErrorCatchProvider.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool,
  isPreview: PropTypes.bool,
  csrfToken: PropTypes.string,
  baseApiPath: PropTypes.shape({
    catchLogger: PropTypes.string,
  }),
  landingConfig: PropTypes.shape({
    groupId: PropTypes.string,
    variantId: PropTypes.string,
    landingVersionId: PropTypes.string,
    productName: PropTypes.string,
    space: PropTypes.string,
  }),
  device: PropTypes.shape({
    type: PropTypes.string,
    webView: PropTypes.bool,
  }),
  scope: PropTypes.string,
  coreValues: PropTypes.shape({
    userId: PropTypes.string,
    coupon: PropTypes.string,
    channel: PropTypes.string,
    subChannel: PropTypes.string,
    siteId: PropTypes.string,
    userAgent: PropTypes.string,
  }),
};

module.exports = ErrorCatchProvider;
