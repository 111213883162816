const React = require('react');
const { arrayOf, bool, string } = require('prop-types');

const { Style } = require('nordic/style');

const { DEPRECATED_SECTIONS } = require('../../../utils/constants');

const SectionStyles = ({ types, theme, isMobile }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {types
      ?.filter((type) => !DEPRECATED_SECTIONS.includes(type))
      .map((type) => (
        <Style
          key={type}
          href={
            isMobile
              ? `${type}-${theme}-mobile.css`
              : `${type}-${theme}-desktop.css`
          }
          critical
        />
      ))}
  </>
);

SectionStyles.propTypes = {
  types: arrayOf(string),
  theme: string,
  isMobile: bool,
};

module.exports = SectionStyles;
